<template>
  <div>
    <b-overlay :show="BALANCE == 0" rounded="sm">
      <b-row>
        <b-col md="6">
          <b-overlay :show="!loaded" rounded="sm">
            <b-card no-body>
              <b-card-header>
                <div class="d-flex align-items-center">
                  <b-avatar
                      variant="primary"
                      text="1"
                  />

                  <b-card-title class="ml-1">
                    {{ $t('Payment method') }}
                  </b-card-title>
                </div>
              </b-card-header>
              <b-card-body>
                {{ $t('Please choose a payment method') }}
                <div class="" v-for="(item,index) in paymentMethods" :key="index" class="mt-2">
                  <b-card @click="paymentId = item.id"
                          :class="{
                        'selected-card-background' : paymentId == item.id,
                        'border-success': paymentId == item.id
                      }"
                          v-model="paymentId"
                          class="card-size rounded mx-auto border h-100 cursor-pointer"
                  >
                    <div>
                      <b-card-text class="font-weight-bold text-dark">{{ item.name }}</b-card-text>
                    </div>
                    <div>
                      <b-card-text class="text-muted">IBAN</b-card-text>
                    </div>
                    <div class="w-100">
                      <div>
                        <b-card-text class="text-muted">** **** **** **** **{{ item.last_four.substring(0, 2) }}
                          {{ item.last_four.substring(2, 4) }}
                        </b-card-text>
                      </div>
                      <div class="position-absolute position-top-0 position-right-0 mr-1 mt-1">
                        <check-circle-icon class="text-success icon-size"
                                           v-if="paymentId == item.id"></check-circle-icon>
                      </div>
                    </div>
                  </b-card>
                </div>
              </b-card-body>
            </b-card>
          </b-overlay>
        </b-col>
        <b-col md="6">
          <b-card no-body>
            <b-card-header>
              <div class="d-flex align-items-center">
                <b-avatar
                    variant="primary"
                    text="2"
                />

                <b-card-title class="ml-1">
                  {{ $t('Confirm payout') }}
                </b-card-title>
              </div>
            </b-card-header>
            <b-card-body>
              <b-alert
                  variant="primary"
                  show
              >
                <div class="alert-body">
                  <span><strong>{{ $t('Amount') }}: </strong> {{ BALANCE }}€</span>
                </div>
              </b-alert>
              <div class="mt-2">
                <b-form-checkbox
                    id="terms-and-condition"
                    v-model="agree"
                    name="terms_and_condition"
                    class="mt-1"
                >{{ $t('I have read the conditional Terms and I agree to it') }}
                </b-form-checkbox>
              </div>
              <div class="mt-2">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    type="submit"
                    size="sm"
                    @click="payOutCredits"
                    :disabled="paymentId == null || BALANCE == 0"
                >
                  {{ $t('Payout {amount}', {amount: BALANCE}) }}
                </b-button>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <template #overlay>
        <div class="text-center">
          <b-alert
              variant="danger"
              :show="BALANCE == 0"

          >
            <div class="alert-body">
              <span><strong>{{ $t('Your balance is zero!') }}</strong> {{ $t('A payout is not possible.') }}</span>
            </div>
          </b-alert>
        </div>
      </template>
    </b-overlay>
  </div>
</template>
<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard, BCardBody, BCardHeader, BCardText, BCardTitle,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BPagination,
  BRow,
  BTable,
  BFormCheckbox, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions'
import TableHeader from '@/views/components/TableHeader'
import TableFooter from '@/views/components/TableFooter'
import {CheckCircleIcon} from "vue-feather-icons";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    BTable,
    BAvatar,
    BAlert,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BCardText,
    BCardTitle,
    BOverlay,
    BFormCheckbox,
    CheckCircleIcon,
    BCardActions,
    TableHeader,
    TableFooter,
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loaded: false,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: {
        input: null
      },
      tableColumns: [

        {
          key: 'account_owner',
          sortable: true,
          label: 'Name',
        },
        {
          key: 'payment_type',
          sortable: true,
          label: 'Payment Method',
          formatter() {
            return 'IBAN'
          }
        },
        {
          key: 'last_four',
          sortable: true,
          label: 'last 4 digits of the IBAN'
        },
        {
          key: 'bic',
          sortable: true,
          label: 'BIC'
        },
        {
          key: 'actions',
          label: 'Actions',
          class: 'table-action-buttons'
        }
      ],
      paymentMethods: [],
      paymentId: null,
      agree: false,
    }
  },

  mounted() {
    this.getPaymentMethods()
  },
  computed: {
    ...mapGetters(['BALANCE'])
  },
  methods: {
    ...mapActions(['GET_BALANCE']),
    getPaymentMethods() {
      this.$http.get('/affiliates/paymentmethods').then(res => {
        this.loaded = true
        this.paymentMethods = res.data.items
      })
    },
    payOutCredits() {
      if (!this.agree) {
        return this.$bvToast.toast(this.$i18n.t('Please accept the terms and conditions.'), {
          title: this.$i18n.t('Error'),
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        })
      }
      if (!this.paymentId) {
        return this.$bvToast.toast(this.$i18n.t('Please select an payment method.'), {
          title: this.$i18n.t('Error'),
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        })
      }
      this.GET_BALANCE()
      this.$http.post('/affiliates/payout', {
        payment_id: this.paymentId
      }).then(response => {
        this.GET_BALANCE()
        // this.helperGetItem(`/experts/credits`)
        // this.creditAmount = 0
        // this.$router.push({name: 'credits-management'})
        this.$bvToast.toast(this.$i18n.t('Credit paid successfully'), {
          title: this.$i18n.t('Success'),
          variant: 'success',
          toaster: 'b-toaster-top-right'
        })

      })
          .catch(e => {
            this.$bvToast.toast(this.$i18n.t('Credit not paid successfully'), {
              title: this.$i18n.t('Error'),
              variant: 'danger',
              toaster: 'b-toaster-top-right'
            })
          })
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
